import { useState } from 'react';

import PropTypes from 'prop-types';

import { Menu, Stack } from '@mui/material';

import { IconChevronDown, IconChevronUp, IconMoreVertical } from 'src/components_payroll/Icons';

import { IconButton, SubmitButton, TextButton } from '../Buttons';

import menuItem, { menuItemProps } from './MenuItem';

const DropDownMenu = ({ Icon, buttonTitle, menuItems, buttonVariant, disabled, loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ChevronIcon = open ? IconChevronDown : IconChevronUp;
  const DefaultIconButtonIcon = Icon || IconMoreVertical;

  const ButtonVariants = {
    light: <SubmitButton title={buttonTitle} isLoading={loading} onClick={handleClick} mode="light" EndIcon={Icon || ChevronIcon} />,
    dark: <SubmitButton title={buttonTitle} isLoading={loading} onClick={handleClick} mode="dark" EndIcon={Icon || ChevronIcon} />,
    text: <TextButton title={buttonTitle} onClick={handleClick} Icon={Icon} padding="0.5rem" />,
    icon: (
      <IconButton
        width="4rem"
        onClick={handleClick}
        Icon={<DefaultIconButtonIcon color={disabled ? 'light.primary.shades.20p' : 'light.primary.shades.60p'} />}
        disabled={disabled}
      />
    ),
  };

  return (
    <Stack
      gap="2rem"
      padding="0.25rem"
      sx={{ backgroundColor: 'inherit', height: '100%' }}
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
    >
      {ButtonVariants[buttonVariant]}
      <Menu
        id="basic-menu"
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '0.31rem' }}
      >
        {menuItems.map(menuItem(handleClose))}
      </Menu>
    </Stack>
  );
};

DropDownMenu.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.oneOf([null])]),
  buttonVariant: PropTypes.oneOf(['light', 'dark', 'text', 'icon']),
  menuItems: PropTypes.arrayOf(PropTypes.shape(menuItemProps)).isRequired,
  loading: PropTypes.bool,
  buttonTitle: props => {
    if (props.buttonVariant !== 'icon') return new Error('Please provide a title function!');
  },
};

DropDownMenu.defaultProps = {
  Icon: null,
  buttonTitle: '',
  buttonVariant: 'icon',
  loading: false,
};

export default DropDownMenu;
